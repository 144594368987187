import React, { useEffect, useState } from 'react'
import { getCountPendenciaDefinirDiagramador } from "../../../services/apis/Pendencia.Api";

const AlertCountPendenciasDefinirDiagramador = () => {

  const [count, setCount] = useState(0);

  useEffect(() => {
    countPendenciaDefinirDiagramador();

  }, []);

  async function countPendenciaDefinirDiagramador() {
    let response = await getCountPendenciaDefinirDiagramador();

    if (response?.data?.value) {
      setCount(response.data.value)
    }
  }

  if (count === 0)
    return null

  return (
    <div className="p-3">
      <div className="alert alert-danger m-0">
        <a href="/pendencia-definir-diagramador" className="text-white">{count} {count === 1 ? "solicitação" : "solicitações"} aguardando definição de diagramador - Clique aqui</a>
      </div>
    </div>
  );
}

export default AlertCountPendenciasDefinirDiagramador;