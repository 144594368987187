import instance from "./Axios.Config";
import { SHOWERROR } from "./../store/actions/actions";
import { clearToken } from "../services/auth";

const interceptor = (store) => {
  instance.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      if (response.data.code == 500) {
        store.dispatch({
          type: SHOWERROR,
          payload:
            response.data.errors != null
              ? response.data.errors
              : response.data.exception != null
              ? [].concat(response.data.exception)
              : [],
        });
        return undefined; // podemos redirecionar para uma tela de ocorreu um erro e tentar nomanete, cmo na funciton error abaixo
      }
      return response;
    },
    function (error) {
      console.log(error.response);
      if (400 === error.response.status) {
        store.dispatch({
          type: SHOWERROR,
          payload: [].concat("Veririque os dados"),
        });
        return Promise.reject(error);
      }
      if (401 === error.response.status) {
         clearToken();

        // console.log(window.location);
        
         window.location = "/login?urlReturn=" + window.location.pathname + (window.location.search || "");
      } else if (500 === error.response.status) {
        store.dispatch({
          type: SHOWERROR,
          payload: [].concat(
            "Ocorreu um erro no servidor para processar essas informações"
          ),
        });
        return Promise.reject(error);
      } else if (403 === error.response.status) {
        store.dispatch({
          type: SHOWERROR,
          payload: [].concat(
            "Você não possui autorização para finalizar a operação"
          ),
        });
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );
};
export default {
  interceptor,
};
