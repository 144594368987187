import instance from "../../helpers/Axios.Config";
import fileInstance from "../../helpers/File.Axios.Config";

var url = "Usuario/";

class UsuarioAPI {

  static async addAsync(obj) {
    try {
      var result = await instance.post(url + "add", obj);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(obj) {
    try {
      var result = await instance.post(url + "update", obj);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async inactivateAsync(id) {
    try {
      var result = await instance.post(url + "inactivate/" + id);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getAsync(id) {
    try {
      var result = await instance.get(url + `get/${id}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getListAsync(params) {
    try {
      var result = await instance.get(url + "get-list", {
        params: params
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getListPagedAsync(params) {
    try {
      var result = await instance.get(url + "get-list-paged", {
        params: params
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCadastroModelAsync(id) {
    try {
      var result = await instance.get(url + "get-cadastro-model/" + id);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getListFornecedoresAsync() {
    try {
      var result = await instance.get(url + "get-list-fornecedor");

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListUsuarioSuperiorAsync() {
    try {

      var result = await instance.get(url + "get-select-list-usuario-superior");

      return result.data.value || [];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  static async getSelectListDiagramadorAsync() {
    try {

      var result = await instance.get(url + "get-select-list-diagramador");

      return result.data.value || [];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListUsuarioAsync() {
    try {

      var result = await instance.get(url + "get-select-list-usuario");

      return result.data.value || [];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListCndAsync() {
    try {

      var result = await instance.get(url + "get-select-list-cnd");

      return result.data.value || [];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  
  static async getSelectListTradeRegionalAsync() {
    try {

      var result = await instance.get(url + "get-select-list-trade-regional");

      return result.data.value || [];
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUsuarioRolesAsync(id) {
    try {
      var result = await instance.get(url + "get-list-usuario-role/" + id);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateUsuarioRolesAsync(model) {
    try {
      var result = await instance.post(url + "update-usuario-roles", model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async resetPassword(id) {
    try {
      var result = await instance.get(url + "reset-password/" + id);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default UsuarioAPI;
