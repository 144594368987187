import { login, logout } from "../services/apis/Login.Api";
import UsuarioAPI from "../services/apis/usuario";

async function authenticate(username, password) {
  var result = await login(username, password);

  if (
    result != undefined &&
    result != null &&
    result.status == 200 &&
    result.data != undefined &&
    result.data.value != undefined &&
    result.data.value.authenticated == true
  ) {
    const {
      value: { authenticated, nome, userId },
      jwtToken,
    } = result.data;
  
    localStorage.setItem(
      'encartes_dpa_admin',
      JSON.stringify({
        jwtToken,
        userId,
        authenticated,
        nome
      })
    );

    localStorage.setItem('token', jwtToken);

    return true;
  }

  return false;
}

async function getLoggedUser() {
  const userData = JSON.parse(localStorage.getItem('encartes_dpa_admin'));

  console.log(userData);

  if (!userData.authenticated == null) {
    window.location = '/login';
    return {};
  }

  return userData;
}

const getToken = () => {
  var token = localStorage.getItem("token");
  return token != null ? token.toString() : undefined;
};

const clearToken = () => {
  localStorage.removeItem('encartes_dpa_admin');
  localStorage.removeItem('token');
  logout();
};

export { authenticate, getToken, clearToken, getLoggedUser };
