import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "../components/organisms/page-loader/index";

import Base from "../components/templates/base/index";
import BasePage from "../components/templates/base-page/index";

import paths from "./paths";


import AlertCountPendenciasDefinirDiagramador from "../components/organisms/alert-count-pendencias-definir-diagramador";

//import AcaoPromocionalCategoria from "../components/pages/acaopromocional-categoria/index";

// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const AcessoNegado = lazy(() => import("../components/pages/acessonegado/index"));

const RelatorioInterno = lazy(() => import("../components/pages/Relatorios/RelatorioInterno/index"));
const RelatorioCliente = lazy(() => import("../components/pages/Relatorios/RelatorioCliente/index"));

const UsuarioTipoIndex = lazy(() => import("../components/pages/usuario-tipo/index"));
const UsuarioTipoRegister = lazy(() => import("../components/pages/usuario-tipo/register"));
const UsuarioTipoDetails = lazy(() => import("../components/pages/usuario-tipo/details"));
const UsuarioTipoRemove = lazy(() => import("../components/pages/usuario-tipo/remove"));
const UsuarioTipoRoles = lazy(() => import("../components/pages/usuario-tipo/roles"));

const UsuarioIndex = lazy(() => import("../components/pages/usuario/index"));
const UsuarioRegister = lazy(() => import("../components/pages/usuario/register"));
const UsuarioDetails = lazy(() => import("../components/pages/usuario/details"));
const UsuarioRemove = lazy(() => import("../components/pages/usuario/remove"));
const UsuarioRoles = lazy(() => import("../components/pages/usuario/roles"));

const UsuarioMeusDados = lazy(() => import("../components/pages/usuario-meusdados/index"));

const FluxoIndex = lazy(() => import("../components/pages/fluxo/index"));
const FluxoRegister = lazy(() => import("../components/pages/fluxo/register"));
const FluxoEtapas = lazy(() => import("../components/pages/fluxo/etapas"));

const Login = lazy(() => import("../components/pages/Login"));

const UnidadeIndex = lazy(() => import("../components/pages/unidade/index"));
const UnidadeRegister = lazy(() => import("../components/pages/unidade/register"));
const UnidadeRemove = lazy(() => import("../components/pages/unidade/remove"));

const ChamadoTipoIndex = lazy(() => import("../components/pages/chamadotipo/index"));
const ChamadoTipoRegister = lazy(() => import("../components/pages/chamadotipo/register"));
const ChamadoTipoDetails = lazy(() => import("../components/pages/chamadotipo/details"));

const PedidoTipoIndex = lazy(() => import("../components/pages/pedidotipo/index"));
const PedidoTipoRegister = lazy(() => import("../components/pages/pedidotipo/register"));

const PerguntaFrequenteIndex = lazy(() => import("../components/pages/perguntafrequente/index"));
const PerguntaFrequenteRegister = lazy(() => import("../components/pages/perguntafrequente/register"));
const PerguntaFrequenteRemove = lazy(() => import("../components/pages/perguntafrequente/remove"));
const PerguntaFrequenteDetails = lazy(() => import("../components/pages/perguntafrequente/details"));

const ProdutoIndex = lazy(() => import("../components/pages/produto/index"));
const ProdutoRemove = lazy(() => import("../components/pages/produto/remove"));
const ProdutoDetails = lazy(() => import("../components/pages/produto/details"));
const ProdutoRegister = lazy(() => import("../components/pages/produto/register"));
const SearchPreco = lazy(() => import("../components/pages/produtos/Search.Preco"));
const ProdutoCategoriaIndex = lazy(() => import("../components/pages/produto-categoria/index"));
const ProdutoCategoriaRegister = lazy(() => import("../components/pages/produto-categoria/register"));
const ProdutoCategoriaRemove = lazy(() => import("../components/pages/produto-categoria/remove"));
const ProdutoAtividadeIndex = lazy(() => import("../components/pages/produto-atividade/index"));
const ProdutoAtividadeRegister = lazy(() => import("../components/pages/produto-atividade/register"));
const OrdemMarcas = lazy(() => import("../components/pages/produto-categoria/Ordem.Marcas"));
const OrdemCategorias = lazy(() => import("../components/pages/produto-categoria/Ordem.Categorias"));
//const OrdemClasses = lazy(() => import("./components/pages/Produtos/Ordem.Classes"));

const LojaIndex = lazy(() => import("../components/pages/loja/index"));
const LojaRegister = lazy(() => import("../components/pages/loja/register"));
const LojaDetails = lazy(() => import("../components/pages/loja/details"));
const LojaRemove = lazy(() => import("../components/pages/loja/remove"));

const DistribuidorIndex = lazy(() => import("../components/pages/distribuidor/index"));
const DistribuidorRegister = lazy(() => import("../components/pages/distribuidor/register"));
const DistribuidorDetails = lazy(() => import("../components/pages/distribuidor/details"));
const DistribuidorRemove = lazy(() => import("../components/pages/distribuidor/remove"));

const Pendencia = lazy(() => import("../components/pages/pendencia/Pendencia"));
const PendenciaStatus = lazy(() => import("../components/pages/pendencia/Pendecia.Status"));
const SearchArquivoEntrega = lazy(() => import("../components/pages/solicitacao/ArquivoEntrega.Search"));
const ArquivoEntrega = lazy(() => import("../components/pages/solicitacao/ArquivoEntrega"));
const ConsultaSolicitacao = lazy(() => import("../components/pages/solicitacao/Consulta.Solicitacao"));
const AlterarSolicitacao = lazy(() => import("../components/pages/solicitacao/Alterar.Solicitacao"));
const AlterarValoresSolicitacao = lazy(() => import("../components/pages/solicitacao/Alterar.Valores.Solicitacao"));
const TrocarEtapaSolicitacao = lazy(() => import("../components/pages/solicitacao/Trocar.Etapa.Solicitacao"));
const AlterarPendencia = lazy(() => import("../components/pages/solicitacao/Alterar.Pendencia"));

const PendenciaDiagramadores = lazy(() => import("../components/pages/pendencia-diagramadores/index"));

const Fornecedor = lazy(() => import("../components/pages/fornecedor/index"));
const FornecedorRegister = lazy(() => import("../components/pages/fornecedor/register"));
const FornecedorDetails = lazy(() => import("../components/pages/fornecedor/details"));
const FornecedorRemove = lazy(() => import("../components/pages/fornecedor/remove"));

const FormaPagamentoIndex = lazy(() => import("../components/pages/formapagamento/index"));
const FormaPagamentoRegister = lazy(() => import("../components/pages/formapagamento/register"));
const FormaPagamentoRemove = lazy(() => import("../components/pages/formapagamento/remove"));

const MotivoAcaoIndex = lazy(() => import("../components/pages/motivoacao/index"));
const MotivoAcaoRegister = lazy(() => import("../components/pages/motivoacao/register"));
const MotivoAcaoRemove = lazy(() => import("../components/pages/motivoacao/remove"));

const AcaoPromocionalCategoria = lazy(() => import("../components/pages/acaopromocional-categoria/index"));
const AcaoPromocionalCategoriaRegister = lazy(() => import("../components/pages/acaopromocional-categoria/register"));

const AcaoPromocional = lazy(() => import("../components/pages/acaopromocional/index"));
const AcaoPromocionalRegister = lazy(() => import("../components/pages/acaopromocional/register"));

const MaterialComunicacaoTipo = lazy(() => import("../components/pages/materialcomunicacao-tipo/index"));
const MaterialComunicacaoTipoRegister = lazy(() => import("../components/pages/materialcomunicacao-tipo/register"));
const MaterialComunicacaoTipoRemove = lazy(() => import("../components/pages/materialcomunicacao-tipo/remove"));

const MaterialComunicacao = lazy(() => import("../components/pages/materialcomunicacao/index"));
const MaterialComunicacaoRegiter = lazy(() => import("../components/pages/materialcomunicacao/register"));
const MaterialComunicacaoDetails = lazy(() => import("../components/pages/materialcomunicacao/details"));
const MaterialComunicacaoRemove = lazy(() => import("../components/pages/materialcomunicacao/remove"));
const MaterialComunicacaoCadastroSolicitacao = lazy(() => import("../components/pages/materialcomunicacao/cadastro-solicitacao"));

const LayoutArteRemove = lazy(() => import("../components/pages/layoutarte/remove"));
const LayoutArteProdutosEspecificos = lazy(() => import("../components/pages/layoutarte/produtos/especificos"));
const LayoutArteProdutosObrigatorios = lazy(() => import("../components/pages/layoutarte/produtos/obrigatorios"));

const LayoutArteAcaoIndex = lazy(() => import("../components/pages/layoutarteacao/index"));
const LayoutArteAcaoRegister = lazy(() => import("../components/pages/layoutarteacao/register"));
const LayoutArteAcaoRemove = lazy(() => import("../components/pages/layoutarteacao/remove"));

const LayoutArteMateriaisIndex = lazy(() => import("../components/pages/layoutarte/materiais/index"));
const LayoutArteMateriaisRegister = lazy(() => import("../components/pages/layoutarte/materiais/register"));

const LayoutArteArtesFacilIndex = lazy(() => import("../components/pages/layoutarte/artes-facil/index"));
const LayoutArteArtesFacilRegister = lazy(() => import("../components/pages/layoutarte/artes-facil/register"));

const CategoriaLayoutIndex = lazy(() => import("../components/pages/categoriaLayout/index"));
const CategoriaLayoutRegister = lazy(() => import("../components/pages/categoriaLayout/register"));
const CategoriaLayoutRemove= lazy(() => import("../components/pages/categoriaLayout/remove"));

const BancoImagemCategoriaIndex = lazy(() => import("../components/pages/bancoimagem-categoria/index"));
const BancoImagemCategoriaRegister = lazy(() => import("../components/pages/bancoimagem-categoria/register"));
const BancoImagemCategoriaRemove = lazy(() => import("../components/pages/bancoimagem-categoria/remove"));

const BancoImagemIndex = lazy(() => import("../components/pages/bancoimagem/index"));
const BancoImagemRegister = lazy(() => import("../components/pages/bancoimagem/register"));
const BancoImagemDetails = lazy(() => import("../components/pages/bancoimagem/details"));
const BancoImagemRemove = lazy(() => import("../components/pages/bancoimagem/remove"));

const BannerLoginIndex = lazy(() => import("../components/pages/bannerlogin/index"));
const BannerLoginRegister = lazy(() => import("../components/pages/bannerlogin/register"));
const BannerLoginRemove = lazy(() => import("../components/pages/bannerlogin/remove"));
const BannerLoginOrder = lazy(() => import("../components/pages/bannerlogin/order"));

const AvisoIndex = lazy(() => import("../components/pages/aviso/index"));
const AvisoRegister = lazy(() => import("../components/pages/aviso/register"));
const AvisoRemove = lazy(() => import("../components/pages/aviso/remove"));
const AvisoOrder = lazy(() => import("../components/pages/aviso/order"));
const AvisoDetails = lazy(() => import("../components/pages/aviso/details"));

const CanalIndex = lazy(() => import("../components/pages/canal/index"));
const CanalRegister = lazy(() => import("../components/pages/canal/register"));
const CanalRemove = lazy(() => import("../components/pages/canal/remove"));

const CampanhaIndex = lazy(() => import("../components/pages/campanha/index"));
const CampanhaRegister = lazy(() => import("../components/pages/campanha/register"));
const CampanhaRemove = lazy(() => import("../components/pages/campanha/remove"));
const CampanhaProdutosEspecificos = lazy(() => import("../components/pages/campanha/produtos/especificos"));
const CampanhaProdutosObrigatorios = lazy(() => import("../components/pages/campanha/produtos/obrigatorios"));

const EtapaLabelIndex = lazy(() => import("../components/pages/etapalabel/index"));
const EtapaLabelRegister = lazy(() => import("../components/pages/etapalabel/register"));
const EtapaLabelRemove = lazy(() => import("../components/pages/etapalabel/remove"));

const RedeIndex = lazy(() => import("../components/pages/rede/index"));
const RedeRegister = lazy(() => import("../components/pages/rede/register"));
const RedeRemove = lazy(() => import("../components/pages/rede/remove"));

const RegionalIndex = lazy(() => import("../components/pages/regional/index"));
const RegionalRegister = lazy(() => import("../components/pages/regional/register"));
const RegionalDetails = lazy(() => import("../components/pages/regional/details"));
const RegionalRemove = lazy(() => import("../components/pages/regional/remove"));

const RegionalVerbaIndex = lazy(() => import("../components/pages/regional-verba/index"));

const ValorDiagramacaoIndex = lazy(() => import("../components/pages/valordiagramacao/index"));
const ValorDiagramacaoRegister = lazy(() => import("../components/pages/valordiagramacao/register"));

const ValorFreteIndex = lazy(() => import("../components/pages/valorfrete/index"));
//const ValorFreteRegister = lazy(() => import("../components/pages/valorfrete/register"));

const ValorProducaoIndex = lazy(() => import("../components/pages/valorproducao/index"));
const ValorProducaoRegister = lazy(() => import("../components/pages/valorproducao/register"));
const ValorProducaoRemove = lazy(() => import("../components/pages/valorproducao/remove"));

const SolicitacaoValorRegister = lazy(() => import("../components/pages/solicitacao/valor-register"));
const SolicitacaoIndex = lazy(() => import("../components/pages/solicitacao/index"));

const SolicitacaoAlterar = lazy(() => import("../components/pages/solicitacao/alterar"));
const SolicitacaoAlterarDados = lazy(() => import("../components/pages/solicitacao/alterar-dados"));
const SolicitacaoAlterarEntrega = lazy(() => import("../components/pages/solicitacao/alterar-entrega"));
const SolicitacaoAlterarProdutos = lazy(() => import("../components/pages/solicitacao/alterar-produtos"));
const SolicitacaoVoltarEtapa = lazy(() => import("../components/pages/solicitacao/voltar-etapa"));

const Home = lazy(() => import("../components/pages/home/index"));
const Logout = lazy(() => import("../components/organisms/logout"));
const NotFound = lazy(() => import("../components/pages/NotFound"));

//const ForumHome = lazy(() => import("./components/Forum/ForumHome"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = ["/login", "/notfound"];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/notfound" component={waitFor(NotFound)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <AlertCountPendenciasDefinirDiagramador />
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route exact path="/home" component={waitFor(Home)} />

                  <Route exact path="/" component={waitFor(Home)} />

                  <Route path="/acessonegado" component={waitFor(AcessoNegado)} />

                  <Route exact path={paths.relatorio + "/RelatorioInterno"} component={waitFor(RelatorioInterno)} />
                  <Route exact path={paths.relatorio + "/RelatorioCliente"} component={waitFor(RelatorioCliente)} />

                  <Route exact path={paths.aviso} component={waitFor(AvisoIndex)} />
                  <Route exact path={`${paths.aviso}/register`} component={waitFor(AvisoRegister)} />
                  <Route exact path={`${paths.aviso}/order`} component={waitFor(AvisoOrder)} />
                  <Route exact path={`${paths.aviso}/remove`} component={waitFor(AvisoRemove)} />
                  <Route exact path={`${paths.aviso}/details`} component={waitFor(AvisoDetails)} />

                  <Route exact path={paths.bancoImagemCategoria} component={waitFor(BancoImagemCategoriaIndex)} />
                  <Route exact path={`${paths.bancoImagemCategoria}/register`} component={waitFor(BancoImagemCategoriaRegister)} />
                  <Route exact path={`${paths.bancoImagemCategoria}/remove`} component={waitFor(BancoImagemCategoriaRemove)} />

                  <Route exact path={paths.bancoImagem} component={waitFor(BancoImagemIndex)} />
                  <Route exact path={`${paths.bancoImagem}/register`} component={waitFor(BancoImagemRegister)} />
                  <Route exact path={`${paths.bancoImagem}/details`} component={waitFor(BancoImagemDetails)} />
                  <Route exact path={`${paths.bancoImagem}/remove`} component={waitFor(BancoImagemRemove)} />

                  <Route exact path={paths.bannerLogin} component={waitFor(BannerLoginIndex)} />
                  <Route exact path={`${paths.bannerLogin}/register`} component={waitFor(BannerLoginRegister)} />
                  <Route exact path={`${paths.bannerLogin}/order`} component={waitFor(BannerLoginOrder)} />
                  <Route exact path={`${paths.bannerLogin}/remove`} component={waitFor(BannerLoginRemove)} />

                  <Route exact path={paths.etapalabel} component={waitFor(EtapaLabelIndex)} />
                  <Route exact path={`${paths.etapalabel}/add`} component={waitFor(EtapaLabelRegister)} />
                  <Route exact path={`${paths.etapalabel}/edit/:id`} component={waitFor(EtapaLabelRegister)} />
                  <Route exact path={`${paths.etapalabel}/remove/:id`} component={waitFor(EtapaLabelRemove)} />

                  <Route exact path={paths.chamadotipo} component={waitFor(ChamadoTipoIndex)} />
                  <Route exact path={`${paths.chamadotipo}/add`} component={waitFor(ChamadoTipoRegister)} />
                  <Route exact path={`${paths.chamadotipo}/edit/:id`} component={waitFor(ChamadoTipoRegister)} />
                  <Route exact path={`${paths.chamadotipo}/details/:id`} component={waitFor(ChamadoTipoDetails)} />

                  <Route exact path={paths.pedidotipo} component={waitFor(PedidoTipoIndex)} />
                  <Route exact path={`${paths.pedidotipo}/add`} component={waitFor(PedidoTipoRegister)} />
                  <Route exact path={`${paths.pedidotipo}/edit/:id`} component={waitFor(PedidoTipoRegister)} />

                  <Route exact path={paths.perguntafrequente} component={waitFor(PerguntaFrequenteIndex)} />
                  <Route exact path={`${paths.perguntafrequente}/add`} component={waitFor(PerguntaFrequenteRegister)} />
                  <Route exact path={`${paths.perguntafrequente}/edit/:id`} component={waitFor(PerguntaFrequenteRegister)} />
                  <Route exact path={`${paths.perguntafrequente}/remove/:id`} component={waitFor(PerguntaFrequenteRemove)} />
                  <Route exact path={`${paths.perguntafrequente}/details/:id`} component={waitFor(PerguntaFrequenteDetails)} />

                  <Route exact path={paths.produto} component={waitFor(ProdutoIndex)} />
                  <Route exact path={`${paths.produto}/register`} component={waitFor(ProdutoRegister)} />
                  <Route exact path={`${paths.produto}/details`} component={waitFor(ProdutoDetails)} />
                  <Route exact path={`${paths.produto}/remove`} component={waitFor(ProdutoRemove)} />

                  <Route exact path={paths.produtoatividade} component={waitFor(ProdutoAtividadeIndex)} />
                  <Route exact path={`${paths.produtoatividade}/add`} component={waitFor(ProdutoAtividadeRegister)} />
                  <Route exact path={`${paths.produtoatividade}/edit/:id`} component={waitFor(ProdutoAtividadeRegister)} />

                  <Route exact path={paths.produtoCategoria} component={waitFor(ProdutoCategoriaIndex)} />
                  <Route exact path={`${paths.produtoCategoria}/register`} component={waitFor(ProdutoCategoriaRegister)} />
                  <Route exact path={`${paths.produtoCategoria}/remove`} component={waitFor(ProdutoCategoriaRemove)} />

                  <Route exact path={paths.unidade} component={waitFor(UnidadeIndex)} />
                  <Route exact path={`${paths.unidade}/register`} component={waitFor(UnidadeRegister)} />
                  <Route exact path={`${paths.unidade}/remove`} component={waitFor(UnidadeRemove)} />

                  <Route exact path={paths.usuarioTipo} component={waitFor(UsuarioTipoIndex)} />
                  <Route exact path={`${paths.usuarioTipo}/register`} component={waitFor(UsuarioTipoRegister)} />
                  <Route exact path={`${paths.usuarioTipo}/details`} component={waitFor(UsuarioTipoDetails)} />
                  <Route exact path={`${paths.usuarioTipo}/remove`} component={waitFor(UsuarioTipoRemove)} />
                  <Route exact path={`${paths.usuarioTipo}/roles`} component={waitFor(UsuarioTipoRoles)} />

                  <Route exact path={paths.usuario} component={waitFor(UsuarioIndex)} />
                  <Route exact path={`${paths.usuario}/register`} component={waitFor(UsuarioRegister)} />
                  <Route exact path={`${paths.usuario}/details`} component={waitFor(UsuarioDetails)} />
                  <Route exact path={`${paths.usuario}/remove`} component={waitFor(UsuarioRemove)} />
                  <Route exact path={`${paths.usuario}/roles`} component={waitFor(UsuarioRoles)} />

                  <Route exact path={paths.usuarioMeusDados} component={waitFor(UsuarioMeusDados)} />

                  <Route exact path={paths.fluxo} component={waitFor(FluxoIndex)} />
                  <Route exact path={`${paths.fluxo}/register`} component={waitFor(FluxoRegister)} />
                  <Route exact path={`${paths.fluxo}/etapas`} component={waitFor(FluxoEtapas)} />

                  <Route exact path={paths.formaPagamento} component={waitFor(FormaPagamentoIndex)} />
                  <Route exact path={`${paths.formaPagamento}/register`} component={waitFor(FormaPagamentoRegister)} />
                  <Route exact path={`${paths.formaPagamento}/remove`} component={waitFor(FormaPagamentoRemove)} />

                  <Route exact path={paths.motivoAcao} component={waitFor(MotivoAcaoIndex)} />
                  <Route exact path={`${paths.motivoAcao}/register`} component={waitFor(MotivoAcaoRegister)} />
                  <Route exact path={`${paths.motivoAcao}/remove`} component={waitFor(MotivoAcaoRemove)} />

                  <Route exact path={paths.acaoPromocionalCategoria}>
                    {" "}
                    <AcaoPromocionalCategoria />
                  </Route>
                  <Route exact path={`${paths.acaoPromocionalCategoria}/register`}
                  >
                    <AcaoPromocionalCategoriaRegister />
                  </Route>
                  <Route exact path={`${paths.acaoPromocionalCategoria}/register/:id`}
                  >
                    <AcaoPromocionalCategoriaRegister />
                  </Route>

                  <Route exact path={paths.acaoPromocional}>
                    {" "}
                    <AcaoPromocional />
                  </Route>
                  <Route exact path={`${paths.acaoPromocional}/add`}>
                    <AcaoPromocionalRegister />
                  </Route>
                  <Route exact path={`${paths.acaoPromocional}/update/:id`}>
                    <AcaoPromocionalRegister />
                  </Route>

                  <Route exact path={paths.layoutArte + "/remove/:id"} component={waitFor(LayoutArteRemove)} />
                  <Route exact path={paths.layoutArte + "/produtos-especificos/:id"} component={waitFor(LayoutArteProdutosEspecificos)} />
                  <Route exact path={paths.layoutArte + "/produtos-obrigatorios/:id"} component={waitFor(LayoutArteProdutosObrigatorios)} />

                  <Route exact path={paths.layoutArteAcao} component={waitFor(LayoutArteAcaoIndex)} />
                  <Route exact path={paths.layoutArteAcao + "/add"} component={waitFor(LayoutArteAcaoRegister)} />
                  <Route exact path={paths.layoutArteAcao + "/update/:id"} component={waitFor(LayoutArteAcaoRegister)} />

                  <Route exact path={paths.layoutArte + "/materiais"} component={waitFor(LayoutArteMateriaisIndex)} />
                  <Route exact path={paths.layoutArte + "/materiais/register/:id?"} component={waitFor(LayoutArteMateriaisRegister)} />

                  <Route exact path={paths.layoutArte + "/artes-facil"} component={waitFor(LayoutArteArtesFacilIndex)} />
                  <Route exact path={paths.layoutArte + "/artes-facil/register/:id?"} component={waitFor(LayoutArteArtesFacilRegister)} />

                  {/* <Route exact path={paths.layoutArte + "/categoriaLayout"} component={waitFor(LayoutArteCategoriaLayoutIndex)} />
                  <Route exact path={paths.layoutArte + "/categoriaLayout/register/:id?"} component={waitFor(LayoutArteCategoriaLayoutRegister)} /> */}

                  <Route exact path={paths.categoriaLayout} component={waitFor(CategoriaLayoutIndex)} />
                  <Route exact path={`${paths.categoriaLayout}/register`} component={waitFor(CategoriaLayoutRegister)} />
                  <Route exact path={`${paths.categoriaLayout}/remove`} component={waitFor(CategoriaLayoutRemove)} />

                  <Route path="/search-preco" component={waitFor(SearchPreco)} />
                  <Route path="/ordem-marcas" component={waitFor(OrdemMarcas)} />
                  <Route path="/ordem-categorias" component={waitFor(OrdemCategorias)} />

                  <Route exact path={paths.loja} component={waitFor(LojaIndex)} />
                  <Route exact path={`${paths.loja}/edit/:id`} component={waitFor(LojaRegister)} />
                  <Route exact path={`${paths.loja}/add`} component={waitFor(LojaRegister)} />
                  <Route exact path={`${paths.loja}/details/:id`} component={waitFor(LojaDetails)} />
                  <Route exact path={`${paths.loja}/remove/:id`} component={waitFor(LojaRemove)} />
                  
                  <Route exact path={paths.distribuidor} component={waitFor(DistribuidorIndex)} />
                  <Route exact path={`${paths.distribuidor}/edit/:id`} component={waitFor(DistribuidorRegister)} />
                  <Route exact path={`${paths.distribuidor}/add`} component={waitFor(DistribuidorRegister)} />
                  <Route exact path={`${paths.distribuidor}/details/:id`} component={waitFor(DistribuidorDetails)} />
                  <Route exact path={`${paths.distribuidor}/remove/:id`} component={waitFor(DistribuidorRemove)} />

                  <Route exact path={paths.fornecedor} component={waitFor(Fornecedor)} />
                  <Route exact path={`${paths.fornecedor}/add`} component={waitFor(FornecedorRegister)} />
                  <Route exact path={`${paths.fornecedor}/edit/:id`} component={waitFor(FornecedorRegister)} />
                  <Route exact path={`${paths.fornecedor}/details/:id`} component={waitFor(FornecedorDetails)} />
                  <Route exact path={`${paths.fornecedor}/remove/:id`} component={waitFor(FornecedorRemove)} />

                  <Route exact path={paths.materialcomunicacaoTipo} component={waitFor(MaterialComunicacaoTipo)} />
                  <Route exact path={`${paths.materialcomunicacaoTipo}/add`} component={waitFor(MaterialComunicacaoTipoRegister)} />
                  <Route exact path={`${paths.materialcomunicacaoTipo}/edit/:id`} component={waitFor(MaterialComunicacaoTipoRegister)} />
                  <Route exact path={`${paths.materialcomunicacaoTipo}/remove/:id`} component={waitFor(MaterialComunicacaoTipoRemove)} />

                  <Route exact path={paths.materialcomunicacao} component={waitFor(MaterialComunicacao)} />
                  <Route exact path={`${paths.materialcomunicacao}/add`} component={waitFor(MaterialComunicacaoRegiter)} />
                  <Route exact path={`${paths.materialcomunicacao}/edit/:id`} component={waitFor(MaterialComunicacaoRegiter)} />
                  <Route exact path={`${paths.materialcomunicacao}/details/:id`} component={waitFor(MaterialComunicacaoDetails)} />
                  <Route exact path={`${paths.materialcomunicacao}/remove/:id`} component={waitFor(MaterialComunicacaoRemove)} />
                  <Route exact path={`${paths.materialcomunicacao}/cadastro-solicitacao`} component={waitFor(MaterialComunicacaoCadastroSolicitacao)} />

                  <Route exact path={paths.canal} component={waitFor(CanalIndex)} />
                  <Route exact path={`${paths.canal}/add`} component={waitFor(CanalRegister)} />
                  <Route exact path={`${paths.canal}/edit/:id`} component={waitFor(CanalRegister)} />
                  <Route exact path={`${paths.canal}/remove/:id`} component={waitFor(CanalRemove)} />

                  <Route exact path={paths.campanha} component={waitFor(CampanhaIndex)} />
                  <Route exact path={paths.campanha + "/add"} component={waitFor(CampanhaRegister)} />
                  <Route exact path={paths.campanha + "/edit/:id"} component={waitFor(CampanhaRegister)} />
                  <Route exact path={paths.campanha + "/remove/:id"} component={waitFor(CampanhaRemove)} />
                  <Route exact path={paths.campanha + "/produtos-especificos/:id"} component={waitFor(CampanhaProdutosEspecificos)} />
                  <Route exact path={paths.campanha + "/produtos-obrigatorios/:id"} component={waitFor(CampanhaProdutosObrigatorios)} />

                  <Route exact path={paths.rede} component={waitFor(RedeIndex)} />
                  <Route exact path={`${paths.rede}/add`} component={waitFor(RedeRegister)} />
                  <Route exact path={`${paths.rede}/edit/:id`} component={waitFor(RedeRegister)} />
                  <Route exact path={`${paths.rede}/remove/:id`} component={waitFor(RedeRemove)} />

                  <Route exact path={paths.regional} component={waitFor(RegionalIndex)} />
                  <Route exact path={`${paths.regional}/add`} component={waitFor(RegionalRegister)} />
                  <Route exact path={`${paths.regional}/edit/:id`} component={waitFor(RegionalRegister)} />
                  <Route exact path={`${paths.regional}/details/:id`} component={waitFor(RegionalDetails)} />
                  <Route exact path={`${paths.regional}/remove/:id`} component={waitFor(RegionalRemove)} />

                  <Route exact path={paths.regionalVerba} component={waitFor(RegionalVerbaIndex)} />

                  <Route exact path={paths.valorDiagramacao} component={waitFor(ValorDiagramacaoIndex)} />
                  <Route exact path={`${paths.valorDiagramacao}/add`} component={waitFor(ValorDiagramacaoRegister)} />
                  <Route exact path={`${paths.valorDiagramacao}/edit/:id`} component={waitFor(ValorDiagramacaoRegister)} />

                  <Route exact path={paths.valorFrete} component={waitFor(ValorFreteIndex)} />
                  {/* <Route exact path={`${paths.valorFrete}/add`} component={waitFor(ValorFreteRegister)} /> */}
                  {/* <Route exact path={`${paths.valorDFrete}/edit/:id`} component={waitFor(ValorFreteRegister)} /> */}

                  <Route exact path={paths.valorProducao} component={waitFor(ValorProducaoIndex)} />
                  <Route exact path={`${paths.valorProducao}/add`} component={waitFor(ValorProducaoRegister)} />
                  <Route exact path={`${paths.valorProducao}/edit/:id`} component={waitFor(ValorProducaoRegister)} />
                  <Route exact path={`${paths.valorProducao}/remove/:id`} component={waitFor(ValorProducaoRemove)} />

                  <Route exact path={paths.solicitacao} component={waitFor(SolicitacaoIndex)} />
                  <Route exact path={`${paths.solicitacaoAlterar}/:solicitacaoId`} component={waitFor(SolicitacaoAlterar)} />
                  <Route exact path={`${paths.solicitacaoAlterarDados}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarDados)} />
                  <Route exact path={`${paths.solicitacaoAlterarEntrega}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarEntrega)} />
                  <Route exact path={`${paths.solicitacaoAlterarProdutos}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarProdutos)} />
                  <Route exact path={`${paths.solicitacaoValores}/:id`} component={waitFor(SolicitacaoValorRegister)} />
                  <Route exact path={`${paths.solicitacaoValores}`} component={waitFor(SolicitacaoValorRegister)} />
                  <Route exact path={`${paths.solicitacaoVoltarEtapa}`} component={waitFor(SolicitacaoVoltarEtapa)} />
                  <Route path="/pendencia-status/:solicitacaoId" component={waitFor(PendenciaStatus)} />
                  <Route path="/search-arquivo-entrega-loja-loja" component={waitFor(SearchArquivoEntrega)} />
                  <Route path="/arquivo-entrega-loja-loja/:solicitacaoId" component={waitFor(ArquivoEntrega)} />
                  <Route path="/consulta-solicitacao" component={waitFor(ConsultaSolicitacao)} />
                  <Route path="/alterar-solicitacao/:solicitacaoId" component={waitFor(AlterarSolicitacao)} />
                  <Route path="/alterar-valores-solicitacao" component={waitFor(AlterarValoresSolicitacao)} />
                  {/* <Route path="/trocar-etapa-solicitacao" component={waitFor(TrocarEtapaSolicitacao)} /> */}
                  <Route path="/alterar-pendencia" component={waitFor(AlterarPendencia)} />

                  <Route path="/logout" component={waitFor(Logout)} />
                  <Route path="/login/:postBackUrl" component={waitFor(Login)} />
                  <Route exact path="/teste" component={waitFor(Pendencia)} />
                  <Route exact path="/pendencia-definir-diagramador" component={waitFor(PendenciaDiagramadores)} />
                  <Redirect to="/notfound" />

                  {/* <Redirect to="/login/:postBackUrl" /> */}
                  {/* Sempre deixar por ultimo */}
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
