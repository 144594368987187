import instance from "../../helpers/Axios.Config";
import fileInstance from "../../helpers/File.Axios.Config";

var url = "Pendencia/";

async function getPendenciasTodosTipos() {
  try {
    var result = await instance.get(url + "get-list-pendencia-usuario");

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getPendenciasDefinirDiagramador() {
  try {
    var result = await instance.get(url + "get-list-pendencia-definir-diagramador");

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getCountPendenciaDefinirDiagramador() {
  try {
    var result = await instance.get(url + "get-count-pendencia-definir-diagramador");

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function definirDiagramador(model) {
  try {
    var result = await instance.post(url + "definir-diagramador", model);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}
async function countPendencia() {
  try {
    var result = await instance.get(url + "get-count-pendencia-usuario");

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getDiagramadores(solicitacaoId) {
  try {
    var result = await instance.get(url + "list-diagramador/" + solicitacaoId);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getFornecedores(solicitacaoId) {
  try {
    var result = await instance.get(url + "list-fornecedor/" + solicitacaoId);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getTrocarEndereco(solicitacaoId) {
  try {
    var result = await instance.get(
      url + "get-troca-endereco/" + solicitacaoId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getVerificarEndereco(solicitacaoId) {
  try {
    var result = await instance.get(
      url + "get-verifica-endereco-pendencia/" + solicitacaoId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarDiagramador(solicitacaoId, diagramadorId) {
  try {
    var result = await instance.post(
      url + "save-diagramador/" + solicitacaoId + "/" + diagramadorId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarFornecedor(solicitacaoId, fornecedorId) {
  try {
    var result = await instance.post(
      url + "save-fornecedor/" + solicitacaoId + "/" + fornecedorId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarVerificarEndereco(solicitacaoId) {
  try {
    var result = await instance.post(
      url + "save-verificar-endereco/" + solicitacaoId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function requestTrocarEndereco(solicitacaoId, motivo) {
  try {
    var result = await instance.post(
      url +
        "criar-requisicao-troca-endereco?solicitacaoId=" +
        solicitacaoId +
        "&motivo=" +
        motivo
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarTrocarEndereco(enderecos) {
  try {
    var result = await instance.post(url + "save-troca-endereco", enderecos);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarDataEntrega(solicitacaoDataEntregaViewModel) {
  try {
    var result = await instance.post(
      url + "save-data-entrega",
      solicitacaoDataEntregaViewModel
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarCodigoRastreio(solicitacaoId, codigoRastreio) {
  try {
    var result = await instance.post(
      url +
        "save-codigo-rastreio?solicitacaoId=" +
        solicitacaoId +
        "&codigoRastreio=" +
        codigoRastreio
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarOrcamento(orcamentoViewModel) {
  try {
    var result = await instance.post(
      url + "save-orcamentos",
      orcamentoViewModel
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function salvarLojaArquivoEntrega(arquivoEntregaViewModel) {
  try {
    var result = await instance.post(
      url + "save-loja-arquivo-entrega",
      arquivoEntregaViewModel
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function getListaPendencia(solicitacaoId) {
  try {
    var result = await instance.get(
      url + "list-pendencia-da-solicitacao/" + solicitacaoId
    );

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function adicionarPendencia(solicitacaoId, etapaId, usuarioId) {
  try {
    let data = new FormData();
    data.append("solicitacaoId", solicitacaoId);
    data.append("etapaId", etapaId);
    data.append("usuarioId", usuarioId);
    var result = await fileInstance.post(url + "add-pendencia", data);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function excluirPendencia(pendenciaId) {
  try {
    var result = await instance.post(url + "delete-pendencia/" + pendenciaId);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

async function reenviarEmails(solicitacaoId) {
  try {
    var result = await instance.post(url + "reenviar-emails/" + solicitacaoId);

    return result;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export {
  getPendenciasTodosTipos,
  getPendenciasDefinirDiagramador,
  getCountPendenciaDefinirDiagramador,
  definirDiagramador,
  countPendencia,
  getDiagramadores,
  getFornecedores,
  getTrocarEndereco,
  getVerificarEndereco,
  salvarDiagramador,
  salvarFornecedor,
  salvarVerificarEndereco,
  requestTrocarEndereco,
  salvarTrocarEndereco,
  salvarDataEntrega,
  salvarCodigoRastreio,
  salvarOrcamento,
  salvarLojaArquivoEntrega,
  getListaPendencia,
  adicionarPendencia,
  excluirPendencia,
  reenviarEmails,
};
